import React from "react";
import { PageMargins, TypographyV2 } from "@components/index";
import * as styles from "./_AI_Hero.module.scss";
import {
	RequestDemoButtonV2,
	SignUpButtonV2,
} from "@components/Button_new/Button";
import { StaticImage } from "gatsby-plugin-image";
import { CurvePath } from "../_AI_Path/_AI_Path";
import AI_VIDEO from "../../_assets/ai-feature-video.mp4";

export function AIHero() {
	function AnchorTerms() {
		return (
			<a href="#chatGptTerms">
				<sup
					style={{
						color: "var(--brand-subtle)",
					}}
				>
					1
				</sup>
			</a>
		);
	}

	return (
		<PageMargins widthOnLargeScreensClass={styles.widthOnLargeScreensClass}>
			<div className={styles.heroContainer}>
				<div className={styles.heroContent}>
					<TypographyV2
						variant="HEADING_1"
						tabletVariant="HEADING_2"
						mobileVariant="HEADING_3"
						weight="SEMIBOLD"
					>
						Genemod AI: The Future of Biopharma ELN
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						color="brand-subtle"
						weight="REGULAR"
					>
						Improve your research workflow with our AI powered lab notebook.
					</TypographyV2>
					<div className={styles.heroButtons}>
						<RequestDemoButtonV2 />
						<SignUpButtonV2 />
					</div>
				</div>
				<div className={styles.heroImage}>
						<video
							className={styles.videoContainer}
							muted
							autoPlay
							loop
              playsInline
              preload="auto"
						>
							<source src={AI_VIDEO} type="video/mp4" />
						</video>
				</div>
			</div>
			<div className={styles.curvePathContainer}>
				<CurvePath />
			</div>
		</PageMargins>
	);
}
