import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { TypographyV2 } from "@components/index";
import * as styles from "./_AI_Section_Component.module.scss";
import { GenemodAILogo } from "../_Genemod_Logo_Container/_Genemod_Logo_Container";
import AI_VIDEO from "@assets/products/AI-chat.mp4";
import { Circle, DottedLine, Arrow, AIBadge } from "../_AI_Path/_AI_Path";
import cn from "classnames";

export type AISection = {
	id: string;
	title: string;
	checks: string[];
	image: JSX.Element;
	header: JSX.Element;
	footer: JSX.Element;
	reverse?: boolean;
};

export function AISections() {
	const ai_sections: AISection[] = [
		{
			id: "ask-genemod",
			title: "Streamline Data Access and Research Support",
			checks: [
				"Obtain immediate, accurate scientific insights using Genemod AI's extensive database.",
				"Blend various research methods with ease, boosting research efficiency and breadth.",
				"Get tailored advice on experimental processes to meet your specific research needs.",
			],
			image: (
				<StaticImage
					src="../../../../assets/products/ai-product/eln-ai-chat.png"
					alt="Screen shot of the AI Chat Sidebar"
				/>
			),
			header: (
				<>
					<Circle />
					<DottedLine length={56} />
					<Arrow />
					<div style={{ margin: "12px 0" }}>
						<GenemodAILogo />
					</div>
					<Circle />
					<DottedLine />
					<Arrow />
				</>
			),
			footer: (
				<>
					<Circle />
					<DottedLine length={32} />
				</>
			),
		},
		{
			id: "revise-text",
			title: "Enhance research report quality",
			checks: [
				"Easily modify your report's tone, ensuring audience-appropriate communication.",
				"Leverage AI-powered grammar checks for clear, fluent research presentations.",
				"Auto-craft detailed research summaries that mirror your precise data.",
			],
			image: (
				<StaticImage
					src="../../../../assets/products/ai-product/ai-improve-writing.png"
					alt="Screen shot of the Revise Text AI Tool"
				/>
			),
			header: (
				<>
					<AIBadge />
					<DottedLine length={21} />
					<Arrow />
				</>
			),
			footer: (
				<>
					<Circle />
					<DottedLine length={72} />
				</>
			),
			reverse: true,
		},
		{
			id: "chatgpt-for-free",
			title: "Genemod AI is powered by GPT",
			checks: [
				"Access GPT directly within Genemod, ensuring a smooth, distraction-free workflow.",
				"Experience a transformative, AI-enhanced lab environment for optimized results.",
				"Discover the unmatched integration of GPT and LIMS/ELN, exclusive to Genemod.",
			],
			image: (
				<StaticImage
					src="../../../../assets/products/ai-product/ai-feature-showcase.png"
					alt="Screen shot of the AI Feature Showcase"
				/>
			),
			header: (
				<>
					<AIBadge />
					<DottedLine length={21} />
					<Arrow />
				</>
			),
			footer: (
				<>
					<Circle />
					<DottedLine length={40} />
					<Arrow />
				</>
			),
		},
	];

	return (
		<>
			{ai_sections.map((section: AISection) => {
				const { checks, image, title } = section;
				return (
					<div className={styles.container}>
						<div className={styles.sectionHeader}>
							{section.header}
						</div>
						<TypographyV2
							className={styles.sectionTitle}
							weight="SEMIBOLD"
							center
							variant="HEADING_2"
							mobileVariant="HEADING_4"
							tabletVariant="HEADING_3"
						>
							{title}
						</TypographyV2>
						<div className={cn(styles.sectionContent, {
							[styles.sectionReverse]: section.reverse,
						})}>
							<ul className={styles.featureList}>
								{checks.map((check: string, idx: number) => (
									<li className={styles.feature}>
										<StaticImage
											src="../../../../assets/icons/check-icon.svg"
											alt="checkmark icon"
											width={32}
											style={{ width: 32, height: 32 }}
										/>
										<TypographyV2
											key={idx}
											weight="MEDIUM"
											variant="BODY_TEXT_EXTRA_LARGE"
                      mobileVariant="BODY_TEXT_LARGE"
										>
											{check}
										</TypographyV2>
									</li>
								))}
							</ul>
							<div style={{ flex: 1 }}>
								<div className={styles.imageContainer}>{image}</div>
							</div>
						</div>
						<div className={styles.sectionFooter}>
							{section.footer}
						</div>
					</div>
				);
			})}
		</>
	);
}
