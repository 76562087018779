import React from "react";
import { GridCol, PageMargins, TypographyV2 } from "@components/index";
import * as styles from "./_AI_You_Wont_Get.module.scss";
import { GenemodLogoContainer } from "../_Genemod_Logo_Container/_Genemod_Logo_Container";
import { AIYouWontGetButton } from "@components/Button_new/Button";

export function AIYouWontGet() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <GenemodLogoContainer />
        <TypographyV2
          className={styles.sectionTitle} color="ui-01" mobileVariant="HEADING_3"
          variant="HEADING_2" weight="MEDIUM" center
        >
          Experience the Genemod AI difference.
        </TypographyV2>
        <AIYouWontGetButton />
      </div>
    </div>
  )
};
