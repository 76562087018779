import { PageLayout } from "@components/index";
import React from "react";
import { AIYouWontGet } from "./_components/_AI_You_Wont_Get/_AI_You_Wont_Get";
import { AISections } from './_components/_AI_Section_Component/_AI_Section_Component'
import { AIHero } from './_components/_AI_Hero/_AI_Hero'

export default function AIProduct() {
    return (
		<PageLayout
			fixedHeaderColor
			seoOptions={{ type: "PREDEFINED", pageName: "ai-product" }}
		>
			<AIHero />
			<AISections />
			<AIYouWontGet />
		</PageLayout>
	);
}